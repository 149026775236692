import React, { useState, useEffect, useRef } from "react"

interface EmbedProps {
    url: string
}

const Embed: React.FC<EmbedProps> = ({ url }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const [iframeLoaded, setIframeLoaded] = useState(false)

    useEffect(() => {
        const handleLoad = () => setIframeLoaded(true)

        if (iframeRef.current) {
            iframeRef.current.addEventListener("load", handleLoad)
        }

        return () => {
            if (iframeRef.current) {
                iframeRef.current.removeEventListener("load", handleLoad)
            }
        }
    }, [])

    return (
        <div>
            <iframe
                ref={iframeRef}
                src={url}
                title="Embedded Content"
                width="100%"
                height="100%"
                style={{ display: iframeLoaded ? "block" : "none" }}
            />
            {!iframeLoaded && <div>Carregando...</div>}
        </div>
    )
}

export default Embed
